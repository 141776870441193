.community {
    display: flex;
    flex-wrap: wrap;

    &__user {
        flex-basis: 164px;
        text-align: center;
        font-size: .85rem;
        cursor: pointer;
    }

    &__user-thumbnail {
        margin-bottom: .5rem;
    }
}
