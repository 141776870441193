.help {
  border: 1px solid $orange;
  padding: .5rem;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  background-color: #fff1ce;
  line-height: 1.2;

  &__link {
    text-align: center;
  }
}
