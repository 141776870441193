.caption-block {

    &__caption {
        margin-bottom: 0;
    }
    &__heading {
        margin-top: 0;
    }

    &--top {
        margin-top: 1.5rem;
    }
}
