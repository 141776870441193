.panel {
    &.panel--clear {
        background-color: $white;
    }

    &-header {
        background-color: $cream;
        padding: 10px;
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
        margin-left: -#{rem-calc(20)};
        margin-right: -#{rem-calc(20)};
        margin-top: -#{rem-calc(20)} !important;
        margin-bottom: rem-calc(20) !important;
    }
}