.u {
    &-flex {
      display: flex;;
    }

    &-unstyled-list {
        list-style: none;
        margin-left: 0;
    }

    &-compulsory:before {
        content: '* ';
        color: $alert-color;
    }

    &-hide {
        display: none !important;
    }

    &-center {
        text-align: center !important;
    }

    &-right {
      text-align: right !important;
    }

    &-inverse {
        background-color: $primary-color;

        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }

        a {
            color: $white;

            &:hover {
                darken: ($white, 20%);
            }
        }
    }

    &-background-white {
        background-color: $white !important;
    }

    &-clearfix-after::after {
        content: " ";
        display: table;
        clear: both;
    }

    &-aligned-bottom {
        position: relative;
        padding-bottom: 2.5rem !important;
    }

    &-align-bottom {
        position: absolute;
        bottom: 1rem;
        margin-bottom: 0;
    }

    &-panel-aligned-bottom {
        position: relative;
        padding-bottom: 4rem;
    }

    &-panel-align-bottom {
        position: absolute;
        bottom: 0;
    }

    &-margin-top {
        margin-top: 1rem;
    }

    &-margin-bottom {
        margin-bottom: 1rem;
    }

    &-double-margin-bottom {
        margin-bottom: 2rem;
  }

    &-padded-top {
        padding-top: 1.2rem;
    }

    &-no-margin-left {
        margin-left: 0 !important;
    }

    &-no-margin-right {
        margin-right: 0 !important;
    }

    &-no-margin-bottom {
        margin-bottom: 0 !important;
    }

    &-page-break-before {
        page-break-before: always;
    }

    &-clear-menu {
      /*
      ** https://css-tricks.com/hash-tag-links-padding/
      */
      display: block;

      &::before {
          display: block;
          content: ' ';
          margin-top: calc(-45px - 1rem); // account for 4em padding
          height: calc(45px + 1rem); // account for 4em padding
          visibility: hidden;
          pointer-events: none;
      }
  }
}
