.submission-message {
   padding: 0.875rem 1.5rem 0.875rem 4rem;
   height: 60px;
   background-color: $orange-half;
   background-image: url(/assets/img/versioned/icons/ka-pai-icon.png);
   background-repeat: no-repeat;
   background-size: 50px 50px;
   background-position: 5px 5px;

   &.submission-message--red {
    background-image: url(/assets/img/versioned/icons/ka-pai-icon-r.png);
   }
}
