.inquiry {
  border: 1px solid #ccc;
  margin-bottom: 1em;
  padding: 1rem;
  background-color: #e0e4d5;

  .inquiry__datetime {
    margin-bottom: .5rem;
    font-size: .75rem;
    font-weight: bold;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  p {
      font-size: .85rem;
      margin-bottom: 0;
  }
}
