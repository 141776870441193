pre {
    margin: 0 0 1rem 0;
    padding: 1rem;
    white-space: pre-wrap;
    font-family: inherit;
    font-size: 0.875rem;
    background-color: #e0e4d5;
    border-radius: 10px;
    min-height: 100px;
}
