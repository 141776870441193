.hero {
    position: relative;
    margin: auto;
    max-width: rem-calc(850);

    img {
        width: 100%;
        margin: auto;
    }

    &__blurb {
        margin-bottom: 0.9375rem;
        padding-top: 0.9375rem;
        padding-bottom: 0.25rem;
        width: 100%;
        overflow: hidden;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        background-color: #ede7b9;
    }

    &__blurb h1 {
        margin-top: 0;
        line-height: 1.1;
        font-size: 2rem;
        font-weight: 400;
    }

    &__blurb p {
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    &__blurb a:hover,
    &__blurb a:focus {
        color: $smoke;
    }

    &__caption {
        display: none;
    }
}

// @media (min-width: 800px) {
    .hero {
        &__caption {
            display: inherit;
            position: absolute;
            bottom: .5rem;
            left: .9375rem;
            color: $white;
            text-shadow: 2px 2px 20px $black;
        }
    }
// }
