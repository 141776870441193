.module {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.9375rem;
        background-color: $quintinary-color;
        color: $white;
        margin-bottom: 1rem;

        a {
            &,
            &:active,
            &:hover,
            &:visited {
                color: $white;
            }
        }
    }

    &__header-icon {
      height: 72px;
      width: 74px;
      background: url("/assets/img/versioned/icons/ngakete_puawai_white.png") !important;
      background-size: cover !important;
    }

    &__learning-log-icon {
      height: 72px;
      width: 74px;
      background: url("/assets/img/versioned/icons/learning-log-r.png") !important;
      background-size: cover !important;
    }

    &__header.module__header--nga-kete-puawai {
      padding: 0.5rem 0.9375rem;
      background-color: $mary-red-full;
    }

    &__header.module__header--tumai-2023 {
      background-color: $mustard;
    }

    &__title {
      display: flex;
      background-color: #ccc;
      padding: .25em;
      justify-content: space-between;
    }

    &__title-icon {
      display: inline-block;
      border-radius: 50%;
      width: 1.25em;
      height: 1.25em;
      margin-right: .5rem;

      &.module__title-icon--icon-whare {
        background: url("/assets/img/versioned/icons/wananga-b-opaque.png") !important;
        background-size: cover !important;
      }

      &.module__title-icon--icon-kete {
        background: url("/assets/img/versioned/icons/ngakete-b-opaque.png") !important;
        background-size: cover !important;
      }
    }

    &__title-text {
      color: $black;
    }

    &__title-back {
      font-size: 1rem;
      line-height: 2;
    }

    &__questions {
        &.module__questions--task {
            .module__question {
                display: inherit;
            }

            .module__question-number {
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }

    &__question {
        display: flex;
        margin-bottom: 2rem;
    }

    &__question-body {
        width: 100%;
    }

    &__question-number {
        min-width: 3.5rem;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.9375rem;
        background-color: lighten($secondary-color, 55%);
        color: $tertiary-color;
        font-size: .85rem;
    }
}
