table tr:nth-of-type(odd) {
    background: darken($cream-quarter, 3%);
}

table td {
    vertical-align: top;
}
table tr.alt, table tr:nth-of-type(even) {
    background: $cream-quarter;
}
