.media-object {
    display: flex;
    align-items: flex-start;

    &__left {
        margin-right: 1em;
    }

    &__right {
        flex: 1;
    }
}
