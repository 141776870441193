.comment {
    border: 1px solid #ccc;
    margin-bottom: 1em;
    padding: 1rem;
    background-color: $white;

    &__heading {
        font-weight: 700;
    }

    &__body {
      word-break: break-word;
    }

    .comment__datetime {
        margin-bottom: .5rem;
        font-size: .75rem;
    }

    p {
        font-size: .85rem;
        margin-bottom: 0;
    }
}
