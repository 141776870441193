.likes {
  text-align: left;

  &__text {
    margin-top: -.25em;
  }

  &.likes--flexed {
    display: flex;
    align-items: center;
    text-align: left;
  }
}
