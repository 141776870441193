/**
 * ACCORDION
 */
.accordion {
    margin-bottom: 1.5rem;
}

.accordion-navigation {
  >a:after {
    font-family: poutamapounamu;
    content: "\e81b";
    color: $ghost;
    line-height: 1;
    float: right;
    margin-right: -2rem;
    font-size: 30px;
  }
  & > a:after:hover {
    //color: scale-color($ghost, $lightness: -5%);
    color: $cream-quarter;
  }
  &.active > a:after {
    font-family: poutamapounamu;
    content: "\e81a";
    color: $white;
  }
}

.accordion .accordion-navigation > a,
.accordion dd > a {
    padding-right: 3rem;
    border-bottom: 3px solid $cream;
}

.accordion .accordion-navigation.accordion-navigation--green > a {
    color: $white;
    background-color: #697739;
}

.accordion .accordion-navigation > a:hover,
.accordion dd > a:hover {
    //color: scale-color($ghost, $lightness: -5%);
    color: $blackish;
}

.accordion .accordion-navigation.active > a,
.accordion dd.active > a {
    color: $blackish;
}

.accordion .accordion-navigation.active.accordion-navigation--green > a {
    color: $white;
}

.accordion .accordion-navigation.accordion-navigation--green > a:hover,
.accordion .accordion-navigation.active.accordion-navigation--green > a:hover {
    color: $white;
    background-color: scale-color(#697739, $lightness: -5%);
}

.accordion__close {
    font-family: poutamapounamu;
    color: red;
    line-height: 1;
    float: right;
    font-size: 30px;
    cursor: pointer;
}

.accordion-navigation.active > div[id^=accordionPanel] {
    padding-bottom: 3rem;
}
