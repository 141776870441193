.progress-bar {
    display: flex;
    position: relative;
    list-style: none;
    margin-top: 1rem;
    margin-left: 0;
    font-size: .8rem;

    &__item {
        position: relative;
        flex: 1 0 0;
        padding-right: .5rem;

        &--connector {
          &:not(:last-child)::before {
              position: absolute;
              top: 1rem;
              left: 0;
              right: 0;
              content: '';
              border-top: 4px solid $green-half;
              width: 100%;
              cursor: pointer;
          }
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            border: 4px solid $green-half;
            background-color: $white;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            cursor: pointer;
        }

        &:last-child::after {
            border: 4px solid $orange-half;
        }

        &.progress-bar__item--icon-whare::after {
          background: url("/assets/img/versioned/icons/wananga-r-opaque.png") !important;
          background-size: cover !important;
          border:none;
          width: 2.25rem;
          height: 2.25rem;
        }

        &.progress-bar__item--icon-kete::after {
            background: url("/assets/img/versioned/icons/ngakete-r-opaque.png") !important;
            background-size: cover !important;
            border:none;
            width: 2.25rem;
            height: 2.25rem;
        }

        &.progress-bar__item--icon-learning-log::after {
          background: url("/assets/img/versioned/icons/learning-log-r-solid.png") !important;
          background-size: cover !important;
          border:none;
          width: 2.25rem;
          height: 2.25rem;
        }

        &.progress-bar__item--bottom {
          flex: 0;
          padding-right: 1.5em;

          &::after {
            background-color: initial;
            width: 0;
            height: 0;
            border: 0;
            top: initial;
          }
        }

        &.progress-bar__item--active::after {
            background-color: $cream;
        }

        &.progress-bar__item--complete {
            &::after {
                background-color: $green-half;
            }

            &.progress-bar__item--active::after {
                background-color: $green;
            }

            &:last-child::after {
                background-color: $orange-half;
            }

            &.progress-bar__item--active:last-child::after {
                background-color: $orange;
            }
        }

        &.progress-bar__item--commented-by-user::after {
            border: 4px solid #b7e80c;
        }

        &.progress-bar__item--commented-by-tutor::after {
            border: 4px solid $quintinary-color;
        }

        &.progress-bar__item--minor {
            flex: .5 0 auto;
            margin-left: -1rem;

            &::after {
                border: 4px solid $green-half;
                top: .65rem;
                width: 1rem;
                height: 1rem;
                background-color: $green-half;
            }
        }
    }

    &__text {
        display: block;
        padding-top: 2.5rem;

        &--bottom {
          height: 6em;
          background-color: lighten($green-half, 30%);
          padding: .75em;
          margin-top: .5em;
          font-weight: $font-weight-bold;
        }
    }

    &.progress-bar--red {
      .progress-bar__item {
        &.progress-bar__item--connector {
          &:not(:last-child)::before {
              border-top: 4px solid $mary-red-half;
          }
        }

        &:not(:first-child)::after {
            border: 4px solid $mary-red-half;
        }

        &:last-child::after {
            border: none;
        }

        &.progress-bar__item--active::after {
          background-color: $mary-red-quarter;
        }

        &.progress-bar__item--complete {
            &::after {
                background-color: $mary-red-half;
            }

            &.progress-bar__item--active::after {
                background-color: $mary-red-full;
            }

            &:last-child::after {
                background-color: $orange-half;
            }

            &.progress-bar__item--active:last-child::after {
                background-color: $mary-red-quarter;
            }
        }

        &.progress-bar__item--commented-by-user::after {
          border: 4px solid #b7e80c;
        }

        &.progress-bar__item--commented-by-tutor::after {
            border: 4px solid gold;
        }

        &.progress-bar__item--minor {
            flex: .5 0 auto;
            margin-left: -1rem;

            &::after {
                border: 4px solid $mary-red-half;
                top: .65rem;
                width: 1rem;
                height: 1rem;
                background-color: $mary-red-half;
            }
        }
      }
    }
}
