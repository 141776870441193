.module-bar {
  display: flex;
  text-align: center;

  &__item {
    flex: 1;
    font-size: 85%;
  }

  &__node {
    display: block;
    background-color: $green-half;
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    margin: auto;

    &.module-bar__node--icon-whare {
      background: url("/assets/img/versioned/icons/wananga-b-opaque.png") !important;
      background-size: cover !important;
    }

    &.module-bar__node--icon-kete {
      background: url("/assets/img/versioned/icons/ngakete-b-opaque.png") !important;
      background-size: cover !important;
}
  }
}

