.profile {
    &__email {
        margin-bottom: 1rem;
    }

    &__heading {
      background-color: #ccc;
      padding: .5rem;
    }

    &__icon {
      width: 2rem;
      margin-right: .5rem;
    }

    @media (min-width: 800px) {
      &__hero {
        max-width: 250px;
        float: left;
        margin-right: 1rem;
        margin-bottom: .25rem;
      }
    }
}
