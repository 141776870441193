h1 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

// user agent stylesheet opacity on iOS for disabled inputs is 0.4, which is too light.
input:disabled,
textarea:disabled {
    opacity: 1;
}
