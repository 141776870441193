.avatar {
  &__image {
    border-radius: 50%;
    height: 4em;
    width: 4em;
  }

  &__name {
    font-size: .8em;
    font-weight: bold;
    word-break: break-word;
  }
}
