.top-branding {
    position: relative;
    height: 60vw;

    &__icon {
        display: none;
        background-color: $white;
        max-height: 100%;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    background-image: url("/assets/img/versioned/logo-text.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 65px;

    @media (min-width: 500px) {
        height: $top-branding-height;
        background-size: auto calc(#{$top-branding-height} - 65px);
    }

    @media (min-width: 700px) {
        &__icon {
            display: block;
        }

        background-position: 10px 55px;

    }

    &.top-branding--bannerless {
        @media (min-width: 450px) {
            height: 45px;
        }
    }
}
