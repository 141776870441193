.draggable {
    counter-reset: draggable;

    &__item {
        position: relative;
        padding: .75rem;
        padding-left: 2rem;
        margin-bottom: .75rem;
        background-color: $cream-half;
        cursor: pointer;

        input {
            position: absolute;
            right: 0;
            width: 0;
            height: 0;
            border: 0;
            margin: 0;
            background-color: $cream-half;
            box-shadow: none;
            cursor: pointer;
        }

        label {
            color: $blackish;
        }

        &[data-value='1'] {
            background-color: $cream-half;

            &:hover {
                background-color: darken($cream-half, 10%);
            }
        }

        &[data-value='2'] {
            background-color: $green-half;

            &:hover {
                background-color: darken($green-half, 10%);
            }
        }

        &[data-value='3'] {
            background-color: $orange-half;

            &:hover {
                background-color: darken($orange-half, 10%);
            }
        }

        &[data-value='4'] {
          background-color: $mustard;

          &:hover {
              background-color: darken($mustard, 10%);
          }
        }

        &[data-value='5'] {
          background-color: $blue;

          &:hover {
              background-color: darken($blue, 10%);
          }
        }

        &:before {
            position: absolute;
            left: .75rem;
            counter-increment: draggable;
            content: counter(draggable) ': ';
            color: $blackish;
        }
    }

    &.draggable--answered {
        .draggable__item {
            cursor: inherit;

            input, label {
                cursor: inherit;
            }

            &[data-value='1'] {
                &:hover {
                    background-color: $cream-half;
                }
            }

            &[data-value='2'] {
                &:hover {
                    background-color: $green-half;
                }
            }

            &[data-value='3'] {
                &:hover {
                    background-color: $orange-half;
                }
            }
        }
    }
}
