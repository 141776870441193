.icon-button {
  display: inline-flex;
  align-items: center;

  &__icon {
    margin-right: .5em;

    &--red {
      color: $mary-red-full;
    }
  }
}
