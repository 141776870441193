.journal {
    table {
        // We want all of our tables on the page to have the same width cells
        table-layout: fixed;
        width: 100%;

        tr, table tr:first-child {
            background-color: lighten(#d7dee1, 10%);
        }

        tr:first-child {
            background-color: #d7dee1;
        }

        tr td:first-child {
            width: 15%;
        }

        td {
            vertical-align: top;

            strong p {
                font-weight: 700;
            }
        }
    }

}