.footer {
    background-color: $cream !important;
    border-top: 1rem solid $quintinary-color;
    padding-top: 1.25rem;

    p {
        font-size: 0.85rem;
    }

    &__copyright {
        margin-top: 0;
        text-align: center;
    }

    &__contact {
        text-align: center;
        margin-bottom: 0;
    }

}
