.kete-tile {

  &__intro {
    border: 2px solid $green;
  }

  &__intro-image {
    width: 100%;
  }

  &__intro-text {
    background-color: $cream-half;
    padding: 1em;

    p {
      margin-bottom: 0;
    }
  }

  &__link {
    position: relative;
    display: block;
  }

  &__icon {
    color: $green;
  }

  &__image {
    width: 100%;
  }

  &__questions-block {
    border: 1px solid $mary-red-half;
    padding: .5rem 1rem;
  }

  &.kete-tile--visited {
    border: 4px solid red;
  }

  @media screen and (min-width: 520px) {
    &__intro {
      display: flex;
      border-left: none;
      border-right: none;
    }

    &__intro-image {
      width: auto;
    }

    &__intro-text {
      border-left: 2px solid $green;
      flex-grow: 1;
    }
  }

  @media screen and (min-width: 800px) {
    &__image {
      width: auto;
    }
  }

}
