button,
.button {
    &.tiny--teeny {
        margin: 0;
        font-size: .85rem;
        padding: 0.325rem 0.5rem 0.3875rem 0.5rem;
    }

    &.button--full-width-minor {
      color: $black;
      padding: .5rem 1rem;
      width: 100%;
      background-color: $mary-red-quarter;

      &:hover {
        background-color: darken($mary-red-quarter, 10%);
      }
    }

    &__monochrome {
        outline: none;

        @include button(
            // $padding - Used to build padding for buttons Default: $button-med or rem-calc(12)
            rem-calc(12),
            // Background color. We can set $bg:false for a transparent background. Default: $primary-color.
            $blackish-quarter,
            // If true, set to button radius which is $global-radius or explicitly set radius amount in px (ex. $radius:10px). Default:false.
            false,
            // We can set $full-width:true to remove side padding extend width. Default:false
            false,
            // We can set $disabled:true to create a disabled transparent button. Default:false
            false
        );
    }
}
