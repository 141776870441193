.checkbox {
    opacity: 0;
    position: absolute;
    cursor: pointer;

    & + label {
        &:before {
            content: "\2713";
            padding-right: 1px;
            border-radius: 0.125rem;

            display: inline-block;
            text-align: center;
            line-height: 2.5;

            border: 0.0625rem solid $cream;
            width: 2.35rem;
            height: 2.35rem;
            margin-right: 0.5rem;

            font-size: 0.875rem;
            color: $white;
            background: $white;
        }
    }

    &[value="1"] + label:before {
        background: $green;
    }
}
