/* http://schinckel.net/2012/01/23/styling-radio-buttons-like-a-segmented-button/
    https://gist.github.com/bjankord/4245356
*/
.question {

    &.question--multichoice {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        margin-left: 0;
        list-style: none;
        // background-color: lighten($quintinary-color, 15%);

        input[type=radio] {
            // We'd like to use 'display: none;', however abide won't consider
            // hidden fields, and 'display: none;' causes fields to appear hidden.
            visibility: hidden;
            position: absolute;
        }

        input:checked + label {
            background-color: darken($green-half, 20%);
        }

        input + label .icon {
            display: none;
        }

        input:checked + label > .icon {
            display: inline;
        }

        label {
            background-color: $green-half;
            padding: .75rem;
            cursor: pointer;
            color: $white;
            margin: 0;
            margin-right: .85em; // instead of justify-content: space-between on the parent
            margin-bottom: .5em;
            border-radius: $global-radius;

            &:hover {
                background-color: darken($green-half, 20%);
            }
        }

        .error.error {
            flex-basis: 100%;
            margin-bottom: 0;
        }
    }

    &.question--answered {
        label {
            &:hover {
                background-color: $green-half;
            }
        }
    }

    &.question--red {
      &.question--multichoice {
        input:checked + label {
          background-color: darken($mary-red-half, 20%);
        }

        label {
          background-color: $mary-red-half;

          &:hover {
            background-color: darken($mary-red-half, 20%);
          }
        }
      }

      &.question--written {
        textarea, pre {
          background-color: $mary-red-quarter;
        }
      }

      &.question--answered {
        label {
            &:hover {
                background-color: $mary-red-half;
            }
        }
      }
    }
}
