.meta-overlay {
  z-index: 1;
  color: $white;
  padding: .25em .25em 0 .25em;

  &--center {
    transform: scale(0.6);
    transform-origin: center;
  }

  &--top-left {
    transform: scale(0.6);
    transform-origin: top left;
    position: absolute;
    top: 0;
    left: 0;
  }

  &--bottom-left {
    transform: scale(0.6);
    transform-origin: bottom left;
  }

  &__icon {
    display: flex;

    &--likes, &--likes-light {
      width: 3rem;
      height: 3rem;
      margin-bottom: .5rem;
    }

    &--likes {
      background: url("/assets/img/versioned/icons/like-r-opaque.png") no-repeat !important;
      background-size: contain!important;
    }

    &--likes-light {
      background: url("/assets/img/versioned/icons/like-w-opaque.png") no-repeat !important;
      background-size: contain!important;
    }

    &--comments, &--comments-light {
      width: 3rem;
      height: 3rem;
      margin-bottom: .5rem;
    }

    &--comments {
      background: url("/assets/img/versioned/icons/comment-r-opaque.png") no-repeat !important;
      background-size: contain!important;
    }

    &--comments-light {
      background: url("/assets/img/versioned/icons/comment-w-opaque.png") no-repeat !important;
      background-size: contain!important;
    }

    &--likes-button {
      width: 1.75rem;
      height: 1.75rem;
      background: url("/assets/img/versioned/icons/like-r-opaque.png") no-repeat !important;
      background-size: contain!important;
    }
  }

  &__icon-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    color: $white;

    &--likes, &--likes-dark {
      width: 3rem;
      height: 4.25rem;
    }

    &--comments, &--comments-dark {
      width: 3rem;
      height: 1.75rem;
    }

    &--likes-dark, &--comments-dark {
      color: $black;
    }

    &--likes-button {
      width: 2.5rem;
      height: 2.5rem;
      font-size: .75em;
    }
  }
}
