.radio-checkbox {
    &__container {
        display: block;
        margin-bottom: 1.5rem;

        &.radio-checkbox__container { // specificity hack
            label {
                display: flex;
            }

            &.error {
                display: inherit;
                background: inherit;
            }
        }
    }

    &__label {
        flex: 1;
        padding: .5rem;
        background-color: $green-quarter;
    }

    &[type=radio] {
        opacity: 0;
        position: absolute;

        &[disabled] + label {
            cursor: default;
        }

        &:checked + label:before {
            background: $green;
        }

        &:checked + label > .radio-checkbox__label {
            background: $green;
            color: $white;
        }

        & + label:before {
            display: inline-block;
            text-align: center;
            line-height: 2.5;

            border: 0.0625rem solid $cream;
            width: 2.35rem;
            height: 2.35rem;
            margin-right: 0.5rem;

            font-size: 0.875rem;
            color: white;
            background: white;
        }
    }

    &[type=radio] {
         & + label:before {
            content: "\2713";
            padding-right: 1px;
            border-radius: 0.125rem;
         }
    }
 }