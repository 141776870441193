.billboard {
    background-color: #6f8937;

    h1, h2, h3, h4, h5, h6, p {
        color: $white;
    }

    a {
      text-decoration: underline;
      color: $white;
    }

    &__body *:last-child {
        padding-bottom: 1rem;
    }
}
