.icon-stack {
  font-size: .65em;
  color: $green;

  &__text {
    text-align: center;
    transform: translateY(20%);
    font-size: .85em;
  }
}
