.home-gallery {
  &__item.home-gallery__item {
    cursor: pointer;
    padding-bottom: .5rem;
  }

  &__caption {
    display: block;
    text-align: center;
  }
}
