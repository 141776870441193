.text {

    &-small {
        font-size: 0.85rem;
    }

    &-left,
    &-left p {
        text-align: left;
    }

    &-centered,
    &-centered p {
        text-align: center;
    }

    &-right,
    &-right p {
        text-align: right;
    }

    &--red {
      color: #cc040e;
    }
}

