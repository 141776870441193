.feedback {

    &__question {
        p, ul {
            margin-bottom: 0;
        }
    }

    &__answer {
        background-color: $green-quarter;
        padding: 1rem;
        margin-bottom: 1rem;

        ul {
            margin-bottom: 0;
        }
    }

}
