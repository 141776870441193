.top-links {
    display: block;
    text-align: right;
    margin-right: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    overflow: hidden;

    &__cta {
        font-size: .95rem;
        margin-bottom: 0;
        &:after {
            display: none;
        }
    }

    &__link {
        font-size: 0.75rem;
        color: $black;
        display: none;
    }

    &.top-links--hidden {
        display: none;
    }
}

@media (min-width: 440px) {
  .top-links {
    &__link {
      display: block;
    }
  }
}

@media (min-width: 840px) {
    .top-links {
        position: absolute;
        top: 45px;
        right: 0;
        text-align: center;
        margin-bottom: 0;
        margin-right: 0;
        width: 325px;
        height: calc(#{$top-branding-height} - 45px);

        &__cta {
            position: absolute;
            top: 60px;
            width: 100%;
            margin-left: 0;
            text-align: left;
            font-size: 1.25rem;
        }

        &__link {
            position: absolute;
            bottom: 10px;
            width: 130px;
            margin-left: 0;
            margin-bottom: 0;
            text-align: left;
            font-size: 1rem;
        }
    }
}
