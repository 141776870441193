.top-bar {

    &-container {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5)
    }

    .top-bar-section {
        text-align: center;

        ul li:last-child>a {
          padding-right: 0.9375rem;
        }

        .dropdown {
            li {
              width: 100%;
            }

            a {
              white-space: nowrap;
            }

            // Dropdown lists should have child elements of parent indented.
            li:nth-child(n+2)>a {
              padding-left: 2rem;
            }

            li:nth-child(n+2) li>a {
              padding-left: 4rem;
            }

            li:nth-child(n+2) li li>a {
              padding-left: 6rem;
            }

            // // Adjust line height to cope with long menus as a result of Nga Kete Puawai
            // @media only screen and (min-width: 840px) {
            //   li:not(.has-form):not(.active) a:not(.button):not(.parent-link) {
            //     line-height: 2.25;
            //   }
            // }
        }
    }

    .top-bar-title {
        font-family: $header-font-family;
        font-style: $header-font-style;
        font-weight: $header-font-weight;
        font-size: 1.5rem;
        color: $white;
    }

    &--main-menu .top-bar-section li:not(.has-form) a:not(.button) {
        padding: 0 0.85rem;
    }

    @media (max-width: $topbar-breakpoint) {
        &--main-menu .top-bar-section li:not(.has-form) a:not(.button) {
            padding: 0.85rem;
        }

        &--main-menu .toggle-topbar.menu-icon {
            margin-top: 0;
        }
        &--main-menu .top-bar-section {
            margin-top: 45px;
        }
    }

}

/**
 * TOP-BAR--TOP-STRIP
 */
.top-bar--top-strip {
    background-color: $primary-color;

    .top-bar-section .has-form {
        padding: 0 0.9375rem;
        transform: translateY(30%);
        background-color: $primary-color;

        @media (min-width: $topbar-breakpoint) {
            transform: translateY(0);
        }

    }

    .top-bar-section ul {
        display: inline;
        height: auto !important;
        width: auto;
    }

    input,
    select,
    .button,
    button {
        font-size: 0.875rem;
        height: 1.75rem;
        position: relative;
    }

    @media (max-width: 500px) {
        .top-bar-section ul {
            width: 100%;
        }
    }

}

/**
 * TOP-BAR--BOTTOM-STRIP
 */
.top-bar--bottom-strip {
    background-color: $primary-color;
    margin-bottom: 2rem;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &.top-bar {
        height: 1.8125rem;
        line-height: 1.8125rem;
    }

    .top-bar-section ul {
        display: inline;
        width: auto;
    }

    .top-bar-section ul li {
        float: left;
        background-color: $primary-color;
    }

    .top-bar-section ul li > a {
        padding-top: 0;
    }

    .top-bar-section li:not(.has-form) a:not(.button) {
        background-color: $primary-color;
    }

    .top-bar-section li:not(.has-form) a:not(.button):hover {
        background-color: $topbar-link-bg-color-hover;
        background: $jet;
    }

    .top-bar-section li:not(.has-form) a:not(.button) {
        line-height: 1.8125rem;
    }

}
