.heading-separated {
    &--major {
        background-color: #ddd;
        padding: .25em;
        margin-bottom: 1.5rem;
        font-weight: 700;
    }

    &--minor {
        border-bottom: 1px solid #000;
        margin-bottom: 1.5rem;
        font-weight: 700;
    }
}
